import {
  ApiResponse,
  Pagination,
  Complaint,
  ComplaintPageModel,
} from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const complaintActions = createActionGroup({
  source: 'Complaint page',
  events: {
    'fetch Complaint': emptyProps(),
    'fetch complaint success': props<{ data: ApiResponse<Complaint[]> }>(),
    'resolve complaints': props<{
      ids: Complaint['id'][];
    }>(),
    'archive complaint': props<{ id: Complaint['id'] }>(),
    'update filter': props<{ data: ComplaintPageModel.filter }>(),
    'update Pagination': props<{ data: Pagination }>(),
    'export to csv': emptyProps(),
  },
});
export default complaintActions;
