import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComplaintState } from './complaint.state';

export const COMPLAINT_STATE_NAME = 'complaint';
const selectComplaintState =
  createFeatureSelector<ComplaintState>(COMPLAINT_STATE_NAME);
export const selectComplaintCount = createSelector(
  selectComplaintState,
  state => state.total
);
const selectComplaints = createSelector(
  selectComplaintState,
  ({ data }) => data
);
const selectFilter = createSelector(
  selectComplaintState,
  ({ filter }) => filter
);
const selectPagination = createSelector(
  selectComplaintState,
  ({ pagination }) => pagination
);

const selectPaginationAndFilter = createSelector(
  selectComplaintState,
  ({ pagination, filter }) => ({ filter, pagination })
);
const selectTotal = createSelector(selectComplaintState, ({ total }) => total);
export default {
  selectFilter,
  selectComplaints,
  selectTotal,
  selectPagination,

  selectPaginationAndFilter,
};
